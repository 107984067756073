import { snakeCase } from 'lodash'

function filterServiceAdjustmentAttributes(fieldConfigs) {
	const serviceAdjustmentFields = fieldConfigs
		.filter(
			(fieldConfig) => fieldConfig.metadata?.['serviceAdjustmentsAttributes'],
		)
		.map((field) => field.key)

	return {
		serviceAdjustmentFields: serviceAdjustmentFields,
		serviceAdjustmentAttributes: [],
	}
}

function serviceAdjustmentSerializer(key, value) {
	return {
		adjustment_type: key.replace('_service_adjustment', ''),
		duration_years: value,
	}
}

function memberSerializer(record, fieldConfigs) {
	const { serviceAdjustmentFields, serviceAdjustmentAttributes } =
		filterServiceAdjustmentAttributes(fieldConfigs)

	const transformed = {}
	const obj = record.values

	Object.keys(obj).forEach((key) => {
		const snakeCaseKey = snakeCase(key)
		const value = obj[key].value

		if (serviceAdjustmentFields?.includes(key)) {
			if (value) {
				serviceAdjustmentAttributes.push(
					serviceAdjustmentSerializer(snakeCaseKey, value),
				)
			}
		} else {
			transformed[snakeCaseKey] = value
		}
	})
	transformed['service_adjustments_attributes'] = serviceAdjustmentAttributes

	return transformed
}

export default memberSerializer
