import axios from 'axios'
import snakeCaseKeys from '../utils/snakeCaseKeys'

const api = axios.create({
	baseURL: '/',
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
})

export async function createCurrentYearMemberData(members, planId) {
	const response = await api.post(
		`/api/plans/${planId}/current_year_member_data`,
		{
			members,
		},
	)
	return response.data
}

export async function updateCurrentYearMemberData(members, planId) {
	const transformedMembers = members.map(snakeCaseKeys)

	const response = await api.patch(
		`/api/plans/${planId}/current_year_member_data`,
		{
			members: transformedMembers,
		},
	)
	return response.data
}

export async function createFinancialData(financialData, planId) {
	const response = await api.post(`/api/plans/${planId}/financial_data`, {
		financial_data: financialData,
	})
	return response.data
}

export async function createHistoricalMemberData(members, planId) {
	const response = await api.post(
		`/api/plans/${planId}/historical_member_data`,
		{
			members,
		},
	)
	return response.data
}

export async function updateHistoricalMemberData(members, planId) {
	const transformedMembers = members.map(snakeCaseKeys)

	const response = await api.patch(
		`/api/plans/${planId}/historical_member_data`,
		{
			members: transformedMembers,
		},
	)
	return response.data
}

export async function deleteSpace(spaceId) {
	const response = await api.delete(`/api/spaces/${spaceId}`)
	return response.data
}
