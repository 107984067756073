import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import {
	AgGridTable,
	ErrorMessage,
	ActionsRenderer,
	WarningsRenderer,
} from 'components'
import { updateHistoricalMemberData } from 'api'

const propTypes = {
	rowData: PropTypes.array.isRequired,
	columnDefs: PropTypes.array.isRequired,
	planId: PropTypes.number.isRequired,
	currentUserRole: PropTypes.string.isRequired,
}

const defaultProps = {}

function HistoricalMembersTable({
	rowData,
	columnDefs,
	planId,
	currentUserRole,
}) {
	const [members, setMembers] = useState(rowData)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const actionsColumn = {
		cellRenderer: ActionsRenderer,
		cellRendererParams: {
			onCellValueChanged: onCellValueChanged,
			currentUserRole: currentUserRole,
			isOnboarded: false,
		},
		field: 'actions',
		headerName: 'Actions',
		pinned: 'right',
		width: 100,
		minWidth: 100,
		maxWidth: 100,
		cellClass: 'shadow-column-right',
	}

	const warningsColumn = {
		cellRenderer: WarningsRenderer,
		cellClass: 'partial-border-right',
		field: 'warnings',
		headerName: '',
		pinned: 'left',
		width: 80,
		resizable: false,
	}

	function onCellValueChanged(updatedRowData) {
		const data = updatedRowData?.data ? updatedRowData.data : updatedRowData
		setMembers(
			members.map((member) =>
				member.id.member === data.id.member ? data : member,
			),
		)
	}

	function getRowClass(params) {
		const dataStatus = params.data.dataManagementStatus.member
		return dataStatus === 'reviewed' && 'shaded-row'
	}

	async function handleSave() {
		setIsSubmitting(true)
		try {
			await updateHistoricalMemberData(members, planId)
			window.location.href = `/plans/${planId}`
		} catch (updateError) {
			setErrorMessage(
				'There was an issue updating members. Please contact an administrator.',
			)
		}
		setIsSubmitting(false)
	}

	return (
		<>
			{errorMessage && (
				<ErrorMessage
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			)}
			<div className="no-sidebar-content">
				<AgGridTable
					rowData={members}
					columnDefs={[warningsColumn, ...columnDefs, actionsColumn]}
					canEdit={true}
					fitToGrid={false}
					showExportButton={false}
					onCellValueChanged={onCellValueChanged}
					getRowClass={getRowClass}
				/>
			</div>
			<div className="actions">
				<button
					className="button-primary button-small"
					onClick={handleSave}
					disabled={isSubmitting}
				>
					{isSubmitting ? 'Saving...' : 'Save & Close'}
				</button>
			</div>
		</>
	)
}

HistoricalMembersTable.propTypes = propTypes
HistoricalMembersTable.defaultProps = defaultProps

export default memo(HistoricalMembersTable)
