import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
	ImportCurrentYearMemberDataButton,
	AgGridTable,
	ActionsRenderer,
	WarningsRenderer,
	ErrorMessage,
	EmptyState,
} from 'components'
import * as Images from 'images'
import LegendModal from './LegendModal.js'
import { MEMBER_DATA_STATUSES_BY_USER_ROLE } from 'config'
import { updateCurrentYearMemberData } from 'api'

const propTypes = {
	planId: PropTypes.number.isRequired,
	lastImportDate: PropTypes.string,
	flatfileConfig: PropTypes.object.isRequired,
	flatfileWorkbook: PropTypes.object.isRequired,
	rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
	columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
	canEdit: PropTypes.bool.isRequired,
	currentUserRole: PropTypes.string.isRequired,
}

const defaultProps = {}

function CurrentYearMembersTable({
	flatfileConfig,
	flatfileWorkbook,
	rowData,
	columnDefs,
	canEdit,
	currentUserRole,
	planId,
	lastImportDate,
}) {
	const [members, setMembers] = useState(rowData)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [modalShown, setModalShown] = useState(false)

	// Action Handling

	const actionsColumn = {
		cellRenderer: ActionsRenderer,
		cellRendererParams: {
			onCellValueChanged: onCellValueChanged,
			currentUserRole: currentUserRole,
		},
		field: 'actions',
		headerName: 'Actions',
		pinned: 'right',
		width: 100,
		minWidth: 100,
		maxWidth: 100,
		cellClass: 'shadow-column-right',
	}

	const warningsColumn = {
		cellRenderer: WarningsRenderer,
		cellClass: 'partial-border-right',
		field: 'warnings',
		headerName: '',
		pinned: 'left',
		width: 80,
		resizable: false,
	}

	const reconciliationList = [
		{
			icon: Images.WarningCircleIcon,
			legend:
				'Changes have occurred in this row. Click the icon to navigate to each respective change.',
		},
		{
			icon: Images.ErrorIcon,
			legend: 'Validation error: Required information is missing or invalid.',
		},
		{
			icon: Images.NewMember,
			legend:
				'New member detected in the latest import. You can review and approve or mark as duplicate.',
		},
		{
			icon: Images.WarningIcon,
			legend:
				'Data change detected within an individual cell from the latest import. No action required, but should be reviewed.',
		},
		{
			icon: Images.QuestionMark,
			legend:
				'Member missing from the previous import. Please review data and update and approve as necessary.',
		},
	]

	// Handlers

	function onCellValueChanged(updatedRowData) {
		const data = updatedRowData?.data ? updatedRowData.data : updatedRowData
		setMembers(
			members.map((member) =>
				member.id.member === data.id.member ? data : member,
			),
		)
	}

	function getRowClass(params) {
		const dataStatus = params.data.dataManagementStatus.member
		const markedDuplicate = params.data.duplicate.member
		return (
			(dataStatus === MEMBER_DATA_STATUSES_BY_USER_ROLE[currentUserRole] ||
				markedDuplicate) &&
			'shaded-row'
		)
	}

	async function handleSave() {
		setIsSubmitting(true)
		try {
			await updateCurrentYearMemberData(members, planId)
			window.location.href = `/plans/${planId}/members`
		} catch (updateError) {
			setErrorMessage(
				'There was an issue updating members. Please contact an administrator.',
			)
		}
		setIsSubmitting(false)
	}

	return (
		<>
			<div className="no-sidebar-content">
				{errorMessage && (
					<ErrorMessage
						errorMessage={errorMessage}
						setErrorMessage={setErrorMessage}
					/>
				)}
				<header className="content-header">
					<div className="header-title">
						<h1>Members</h1>
					</div>
					<div className="details">
						<div className="manage-details">
							<p>
								Review, edit, and organize unreconciled member data. Identify
								differences between imports with highlighted cells, make edits,
								and efficiently flag rows as reviewed or duplicates for a
								cleaner final submission.
							</p>
							<button
								className="button-neutral-outline button-small legend"
								onClick={() => setModalShown(true)}
							>
								View Legend
							</button>
						</div>
						<div className="action-details">
							{lastImportDate && (
								<>
									<p className="secondary-text">
										Last import: {lastImportDate}
									</p>
									<div className="vertical-line"></div>
								</>
							)}
							<ImportCurrentYearMemberDataButton
								flatfileConfig={flatfileConfig}
								workbook={flatfileWorkbook}
								planId={planId}
								setData={setMembers}
								setErrorMessage={setErrorMessage}
							/>
						</div>
					</div>
				</header>
				{isEmpty(members) ? (
					<EmptyState
						title={'No Members to Reconcile'}
						text={
							'It looks like there are no members for reconciliation or review. To import members, click the "Import Members" button to begin the process.'
						}
						imageSource={Images.EmptyStateCactus}
						imageAltText="No Members to Reconcile"
					/>
				) : (
					<AgGridTable
						rowData={members}
						columnDefs={[warningsColumn, ...columnDefs, actionsColumn]}
						canEdit={canEdit}
						fitToGrid={false}
						showExportButton={false}
						onCellValueChanged={onCellValueChanged}
						getRowClass={getRowClass}
					/>
				)}
				{modalShown && (
					<LegendModal
						modalShown={modalShown}
						setModalShown={setModalShown}
						modalTitle="Table Legend"
						itemList={reconciliationList}
						handleConfirm={() => setModalShown(false)}
						confirmationText="Done"
					/>
				)}
			</div>
			{!isEmpty(members) && (
				<div className="actions">
					<button
						className="button-primary button-small"
						onClick={handleSave}
						disabled={isSubmitting}
					>
						{isSubmitting ? 'Saving...' : 'Save & Close'}
					</button>
				</div>
			)}
		</>
	)
}

CurrentYearMembersTable.propTypes = propTypes
CurrentYearMembersTable.defaultProps = defaultProps

export default memo(CurrentYearMembersTable)
