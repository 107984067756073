import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { startCase, camelCase } from 'lodash'

const propTypes = {
	value: PropTypes.string,
	colorMap: PropTypes.object,
}

const defaultProps = {}

function StatusRenderer({ value, colorMap }) {
	return (
		<>
			{value && colorMap && (
				<span className={classNames('status', colorMap[camelCase(value)])}>
					{startCase(value)}
				</span>
			)}
		</>
	)
}

StatusRenderer.propTypes = propTypes
StatusRenderer.defaultProps = defaultProps

export default memo(StatusRenderer)
