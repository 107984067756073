import { capitalize, startCase } from 'lodash'

export const formatCurrency = (number) =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	}).format(number)

export const currencyFormatter = ({ value }) => {
	if (!value) return
	return formatCurrency(value)
}

export const capitalizeFormatter = ({ value }) => capitalize(value)

export const enumFormatter = ({ value }) => startCase(value)

export const ssnFormatter = ({ value }) => {
	if (!value) return
	return `XXX-XX-${value.slice(-4)}`
}
