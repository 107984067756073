import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Modal from 'react-modal'

const propTypes = {
	handleConfirm: PropTypes.func,
	modalShown: PropTypes.bool,
	setModalShown: PropTypes.func,
	modalTitle: PropTypes.string,
	modalContent: PropTypes.string,
	cancelText: PropTypes.string,
	confirmationText: PropTypes.string,
}

const defaultProps = {
	cancelText: 'Cancel',
	confirmationText: 'Confirm',
}

function ConfirmationModal({
	modalShown,
	setModalShown,
	modalTitle,
	modalContent,
	handleConfirm,
	cancelText,
	confirmationText,
}) {
	return (
		<div className="modal">
			<Modal
				isOpen={modalShown}
				ariaHideApp={false}
				contentLabel="onRequestClose Example"
				onRequestClose={() => setModalShown(false)}
				shouldCloseOnOverlayClick={true}
				className="modal-content"
				overlayClassName="modal-overlay"
			>
				<button onClick={() => setModalShown(false)} className="modal-close">
					X
				</button>
				<h3>{modalTitle}</h3>
				<div>
					<p>{modalContent}</p>
				</div>
				<div className="modal-buttons">
					<button
						className="button-light button-small"
						onClick={() => setModalShown(false)}
					>
						{cancelText}
					</button>
					<button
						className="button-primary button-small"
						onClick={handleConfirm}
					>
						{confirmationText}
					</button>
				</div>
			</Modal>
		</div>
	)
}

ConfirmationModal.propTypes = exact(propTypes)
ConfirmationModal.defaultProps = defaultProps

export default memo(ConfirmationModal)
