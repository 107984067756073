import React, { useState } from 'react'
import Select from 'react-select'

function setOptions(items, labelKey, valueKey) {
	return items
		.map((item) => ({
			label: item[labelKey],
			value: item[valueKey],
		}))
		.sort((a, b) => a.label.localeCompare(b.label))
}

const SearchableSelect = ({
	items,
	labelKey,
	valueKey,
	hiddenFieldId,
	isMulti,
	initialItems,
}) => {
	const options = setOptions(items, labelKey, valueKey)
	const [selectedOptions, setSelectedOptions] = useState(
		setOptions(initialItems, labelKey, valueKey),
	)

	const handleChange = (selected) => {
		setSelectedOptions(selected)
		// Update the hidden field with the selected value(s)
		const hiddenField = document.getElementById(hiddenFieldId)
		if (hiddenField) {
			const selectedValues = isMulti
				? selected.map((option) => option.value)
				: selected.value
			hiddenField.value = selectedValues
		}
	}

	return (
		<div>
			<Select
				options={options}
				isMulti={isMulti}
				value={selectedOptions}
				onChange={handleChange}
				isSearchable={true}
				placeholder="Search or Select..."
			/>
		</div>
	)
}

export default SearchableSelect
