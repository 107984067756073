import React, { useState, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { ConfirmationModal, Tooltip } from 'components'
import { MEMBER_DATA_STATUSES_BY_USER_ROLE } from 'config'

const propTypes = {
	data: PropTypes.shape({
		id: PropTypes.shape({
			member: PropTypes.number.isRequired,
			newMember: PropTypes.bool.isRequired,
		}),
		dataManagementStatus: PropTypes.shape({
			member: PropTypes.string,
		}),
		duplicate: PropTypes.object,
	}).isRequired,
	onCellValueChanged: PropTypes.func.isRequired,
	currentUserRole: PropTypes.string.isRequired,
	isOnboarded: PropTypes.bool,
}

const defaultProps = {
	isOnboarded: true,
}

const TOOLTIP_TEXT = {
	status: {
		underReview: 'Mark as reviewed',
		notUnderReview: 'Mark as under review',
	},
	duplicate: {
		true: 'Unmark as duplicate',
		false: 'Mark as duplicate',
	},
}

function getTooltipText(buttonType, status, currentUserRole) {
	switch (buttonType) {
		case 'acceptChanges':
			return status === MEMBER_DATA_STATUSES_BY_USER_ROLE[currentUserRole]
				? TOOLTIP_TEXT.status.notUnderReview
				: TOOLTIP_TEXT.status.underReview
		case 'duplicate':
			return TOOLTIP_TEXT.duplicate[status]
		default:
			return ''
	}
}

function ActionsRenderer({
	data,
	onCellValueChanged,
	currentUserRole,
	isOnboarded,
}) {
	const { newMember } = data.id
	const dataStatus = data.dataManagementStatus.member
	const [modalShown, setModalShown] = useState(false)
	const [tooltipVisible, setTooltipVisible] = useState(false)
	const [tooltipContent, setTooltipContent] = useState('')
	const buttonRef = useRef(null)

	function handleStatusClick() {
		const newStatus =
			dataStatus === MEMBER_DATA_STATUSES_BY_USER_ROLE[currentUserRole]
				? 'under_review'
				: MEMBER_DATA_STATUSES_BY_USER_ROLE[currentUserRole]

		const updatedData = {
			...data,
			dataManagementStatus: {
				...data.dataManagementStatus,
				member: newStatus,
			},
		}

		onCellValueChanged(updatedData)
	}

	function handleDuplicateToggle() {
		const duplicateRecord = !data.duplicate.member

		const updatedData = {
			...data,
			duplicate: {
				...data.duplicate,
				member: duplicateRecord,
			},
		}

		onCellValueChanged(updatedData)
	}

	function showTooltip(content) {
		setTooltipContent(content)
		setTooltipVisible(true)
	}

	function hideTooltip() {
		setTooltipVisible(false)
	}

	return (
		<div className="cell-actions">
			<button
				ref={buttonRef}
				className={
					dataStatus === MEMBER_DATA_STATUSES_BY_USER_ROLE[currentUserRole]
						? 'btn-checkmark-clicked'
						: 'btn-checkmark-default'
				}
				onClick={handleStatusClick}
				alt="Accept Row Changes"
				onMouseEnter={() =>
					showTooltip(
						getTooltipText('acceptChanges', dataStatus, currentUserRole),
					)
				}
				onMouseLeave={hideTooltip}
			></button>

			{newMember && isOnboarded && (
				<>
					<button
						ref={buttonRef}
						className={
							data.duplicate.member
								? 'btn-duplicate-clicked'
								: 'btn-duplicate-default'
						}
						onClick={() => {
							data.duplicate.member
								? handleDuplicateToggle()
								: setModalShown(true)
						}}
						alt="Mark row as duplicate"
						onMouseEnter={() =>
							showTooltip(getTooltipText('duplicate', data.duplicate.member))
						}
						onMouseLeave={hideTooltip}
					/>
					{modalShown && (
						<ConfirmationModal
							modalShown={modalShown}
							setModalShown={setModalShown}
							modalTitle="Mark Duplicate"
							modalContent="You're about to mark this member as a duplicate, and they will no longer appear in the list. Be sure to note any changes and make the appropriate updates to the existing member on the index page. Are you sure you want to mark as a duplicate?"
							handleConfirm={handleDuplicateToggle}
							confirmationText="Yes, Mark Duplicate"
						/>
					)}
				</>
			)}
			<Tooltip referenceElement={buttonRef.current} visible={tooltipVisible}>
				{tooltipContent}
			</Tooltip>
		</div>
	)
}

ActionsRenderer.propTypes = propTypes
ActionsRenderer.defaultProps = defaultProps

export default memo(ActionsRenderer)
