import { FlatfileListener } from '@flatfile/listener'
import {
	completeJob,
	failJob,
	getRowsAndFieldConfigs,
	financialDataSerializer,
	handleImportError,
} from 'utils'
import { createFinancialData } from 'api'

const FINANCIAL_DATA_IMPORT_ERROR =
	'There was an issue importing financial data. Please contact an administrator.'

function financialDataListener(planId, setErrorMessage, setShowSpace) {
	return FlatfileListener.create((listener) => {
		listener.filter({ job: 'workbook:financialData:submit' }, (configure) => {
			configure.on('job:ready', async ({ context: { jobId, workbookId } }) => {
				try {
					const { rows } = await getRowsAndFieldConfigs(workbookId)

					try {
						const financialDataRecord = financialDataSerializer(rows)
						await createFinancialData(financialDataRecord, planId)

						await completeJob(jobId)
					} catch (importError) {
						await handleImportError(
							jobId,
							importError,
							FINANCIAL_DATA_IMPORT_ERROR,
							setErrorMessage,
							setShowSpace,
						)
					}
				} catch (error) {
					await failJob(jobId)
				}
			})
		})
	})
}

export default financialDataListener
