import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ConfirmationModal } from 'components'
import { Logo } from 'images'

const propTypes = {
	title: PropTypes.string.isRequired,
	exitPath: PropTypes.string.isRequired,
}
const defaultProps = {}

function ActionHeader({ title, exitPath }) {
	const [modalShown, setModalShown] = useState(false)

	function handleConfirm() {
		window.location.href = `${window.location.origin}${exitPath}`
	}

	return (
		<>
			<header className="page-header with-actions">
				<div className="logo-block">
					<img src={Logo} alt={'Foster & Foster Logo'} className="logo" />
					<div className="vertical-line"></div>
					<p>{title}</p>
				</div>
				<button
					className="button close"
					onClick={() => setModalShown(true)}
				></button>
			</header>
			{modalShown && (
				<ConfirmationModal
					modalShown={modalShown}
					setModalShown={setModalShown}
					modalTitle="Unsaved Changes"
					modalContent="You're about to exit without saving any changes. Are you sure you wish to continue?"
					handleConfirm={handleConfirm}
					confirmationText="Yes, Exit"
				/>
			)}
		</>
	)
}

ActionHeader.propTypes = exact(propTypes)
ActionHeader.defaultProps = defaultProps

export default memo(ActionHeader)
