function setReconciliationClass({ colDef: { field }, data }) {
	const fieldName = field.split('.')[0]
	const fieldData = data[fieldName]

	if (fieldData?.needsReconciliation) {
		return 'with-warning'
	} else if (fieldData?.invalid) {
		return 'with-error'
	}
}

export default setReconciliationClass
